/*!

 =========================================================
 * Vivoleo React Kit v1.10.0 based on Material Kit Free - v2.0.2 (Bootstrap 4.0.0 Final Edition)
 =========================================================

 * Product Page: https://www.vivoleo.com/product/vivoleo-react-kit
 * Copyright 2021 Vivoleo Dev (https://www.vivoleo.com)
 * Licensed under MIT (https://github.com/vivoleo/vivoleo-react-kit/blob/master/LICENSE.md)

 * Coded by Vivoleo Dev

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
@import "core/variables";
@import "core/mixins";

// Core Components
@import "core/misc";

// Core Plugins

// @import "plugins/plugin-datetime-picker";
@import "plugins/plugin-nouislider";
@import "plugins/plugin-react-datetime";
@import "plugins/plugin-react-slick";
